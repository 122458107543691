import React, { useState, createContext, useContext, useEffect } from "react";

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
    const [loggedIn, setLoggedIn] = useState(false)
    const [deviceRegistered, setDeviceRegistered] = useState(true)
    const [permissionAsked, setPermissionAsked] = useState(false)

    return (
        <AuthContext.Provider value={{ loggedIn, setLoggedIn, deviceRegistered, setDeviceRegistered, permissionAsked, setPermissionAsked }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext)
    if (context === undefined) {
        throw new Error(`useAuth must be used within a AuthProvider`)
    }
    return context
}