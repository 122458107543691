import { Spinner } from '@ui-kitten/components';
import React from 'react';
import ImageViewer from 'react-native-image-zoom-viewer';

const ImageScreen = ({ navigation, route }) => {

    React.useEffect(() => {
        console.log(route.params.imageUrl);
    }, [])

    return (
        <ImageViewer
            enableSwipeDown={true}
            onSwipeDown={() => {
                navigation.goBack()
            }}
            loadingRender={() => (<Spinner />)}
            imageUrls={[{
                url: route.params.imageUrl
            }]} />
    )
}

export default ImageScreen;