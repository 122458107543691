import React from 'react';
import { Alert, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Network from 'expo-network';
import * as Application from 'expo-application';
import { apiUrl, username, password } from './Constants';
import alert from './alert';

export const sendLocationTaskName = 'background-send-location-absen-mobile';

const axios = require('axios');

export const version = 1;

export const getDeviceIdentifier = async () => {
    try {
        var deviceId = await AsyncStorage.getItem('@identifier');
        if (deviceId != null) {
            return deviceId;
        } else {
            var deviceId = "";
            if (Platform.OS === 'ios') {
                deviceId = await Application.getIosIdForVendorAsync();
            } else if (Platform.OS === 'android') {
                deviceId = Application.androidId;
            }
            await AsyncStorage.setItem('@identifier', deviceId);
            return deviceId;
        }

    } catch (e) {
        console.log(e);
        var deviceId = "";
        if (Platform.OS === 'ios') {
            deviceId = await Application.getIosIdForVendorAsync();
        } else if (Platform.OS === 'android') {
            deviceId = Application.androidId;
        }
        await AsyncStorage.setItem('@identifier', deviceId);
        return deviceId;
    }
}

export const getToken = async () => {
    await axios.post(`${apiUrl}token`, {
        username: username,
        password: password
    }).then(function (response) {
        const data = response.data;
        if (data.metadata.code == 200 || data.metadata.code == "200") {
            (async () => await AsyncStorage.setItem('@token', data.response.token))();
        } else {
            alert(
                "Gagal mendapatkan token API!",
                response.metadata.message,
                [{ text: "Ok" }],
                {
                    cancelable: true
                }
            )
        }
        // console.log(response)
    }).catch(function (error) {
        alert(
            "Error!",
            "Terjadi kesalahan!",
            [{ text: "Ok" }],
            {
                cancelable: true
            }
        )
        console.log(error)
    })
}

export const checkDevice = async (appid, setDeviceRegistered) => {

    try {

        await getToken();
        var token = await AsyncStorage.getItem('@token')

        await axios.post(`${apiUrl}register_device/check_device`, {
            appid: appid,
            version: version
        }, {
            headers: { 'x-token': token }
        }).then(function (response) {
            const data = response.data
            console.log(data);

            if (parseInt(data.metadata.code) == 200) {
                (async () => await AsyncStorage.setItem('@registered_nik', data.response.nik))();
                setDeviceRegistered(true)
            } else {
                (async () => {
                    await AsyncStorage.removeItem('@registered_nik');
                    await removeDataPegawai()
                })();
                setDeviceRegistered(false)
                alert(
                    "Gagal!",
                    data.metadata.message,
                    [{ text: "Ok" }],
                    {
                        cancelable: true
                    }
                )
            }
            console.log(data)
        }).catch(function (error) {
            alert(
                "Error!",
                "Terjadi kesalahan!",
                [{ text: "Ok" }],
                {
                    cancelable: true
                }
            )
            console.log(error)
        })
    } catch (e) {
        alert(
            "Error!",
            "Terjadi kesalahan!",
            [{ text: "Ok" }],
            {
                cancelable: true
            }
        )
        console.log(e)
    }
}

export const doRegisterDevice = async (appid, nik, setDeviceRegistered, setLoading, setLoggedIn) => {
    setLoggedIn(false);
    try {

        await getToken()
        var token = await AsyncStorage.getItem('@token')

        await axios.post(`${apiUrl}register_device/register`, {
            appid: appid,
            nik: nik,
            version: version
        }, {
            headers: { 'x-token': token }
        }).then(function (response) {
            const data = response.data

            if (parseInt(data.metadata.code) == 200) {
                if (data.response.status) {
                    (async () => await AsyncStorage.setItem('@registered_nik', nik))();
                    setDeviceRegistered(true);

                    alert(
                        "Berhasil!",
                        data.metadata.message,
                        [{ text: "Ok" }],
                        {
                            cancelable: true
                        }
                    )
                } else {
                    (async () => await AsyncStorage.removeItem('@registered_nik'))();
                    setLoading(false)
                    setDeviceRegistered(false);

                    alert(
                        "Gagal!",
                        data.metadata.message,
                        [{ text: "Ok" }],
                        {
                            cancelable: true
                        }
                    )
                }
            } else {
                setLoading(false)
                setDeviceRegistered(false)
                alert(
                    "Gagal!",
                    data.metadata.message,
                    [{ text: "Ok" }],
                    {
                        cancelable: true
                    }
                )
            }
            console.log(data)
        }).catch(function (error) {
            setLoading(false)
            setDeviceRegistered(false)
            alert(
                "Error!",
                "Terjadi kesalahan!",
                [{ text: "Ok" }],
                {
                    cancelable: true
                }
            )
            console.log(error)
        })

    } catch (e) {
        setLoading(false)
        setDeviceRegistered(false)
        alert(
            "Error!",
            "Terjadi kesalahan!",
            [{ text: "Ok" }],
            {
                cancelable: true
            }
        )
        console.log(e)
    }

}

export const doResetPassword = async (appid, nik, email, setSucces, setLoading) => {
    try {

        await getToken()
        var token = await AsyncStorage.getItem('@token')

        await axios.post(`${apiUrl}login/reset_password`, {
            nik: nik,
            email: email
        }, {
            headers: {
                'x-token': token,
                'x-appid': appid,
                'x-version': version
            }
        }).then(function (response) {
            setLoading(false)
            const data = response.data

            if (parseInt(data.metadata.code) == 200) {
                if (data.response.status) {
                    setSucces(true)

                    alert(
                        "Berhasil!",
                        data.metadata.message,
                        [{ text: "Ok" }],
                        {
                            cancelable: true
                        }
                    )
                } else {
                    setSucces(false)

                    alert(
                        "Gagal!",
                        data.metadata.message,
                        [{ text: "Ok" }],
                        {
                            cancelable: true
                        }
                    )
                }
            } else {
                setLoading(false)
                setSucces(false)

                alert(
                    "Gagal!",
                    data.metadata.message,
                    [{ text: "Ok" }],
                    {
                        cancelable: true
                    }
                )
            }
        }).catch(function (error) {
            setLoading(false)
            setSucces(false)
            alert(
                "Error!",
                `Terjadi kesalahan! ${error.message}`,
                [{ text: "Ok" }],
                {
                    cancelable: true
                }
            )
            console.log(error)
        })

    } catch (e) {
        setLoading(false)
        setSucces(false)
        alert(
            "Error!",
            `Terjadi kesalahan! ${e.message}`,
            [{ text: "Ok" }],
            {
                cancelable: true
            }
        )
    }

}

export const submitNewPass = async (appid, nik, tokenEmail, pass, passConf, setSuccess, setLoading) => {
    try {

        await getToken()
        var token = await AsyncStorage.getItem('@token')

        await axios.post(`${apiUrl}login/submit_reset_password`, {
            nik: nik,
            token: tokenEmail,
            pass: pass,
            pass_conf: passConf,
        }, {
            headers: {
                'x-token': token,
                'x-appid': appid,
                'x-version': version
            }
        }).then(function (response) {
            setLoading(false)
            const data = response.data

            if (parseInt(data.metadata.code) == 200) {
                if (data.response.status) {
                    setSuccess(true)

                    alert(
                        "Berhasil!",
                        data.metadata.message,
                        [{ text: "Ok" }],
                        {
                            cancelable: true
                        }
                    )
                } else {
                    setSuccess(false)

                    alert(
                        "Gagal!",
                        data.metadata.message,
                        [{ text: "Ok" }],
                        {
                            cancelable: true
                        }
                    )
                }
            } else {
                setLoading(false)
                setSuccess(false)

                alert(
                    "Gagal!",
                    data.metadata.message,
                    [{ text: "Ok" }],
                    {
                        cancelable: true
                    }
                )
            }
        }).catch(function (error) {
            setLoading(false)
            setSuccess(false)
            alert(
                "Error!",
                `Terjadi kesalahan! ${error.message}`,
                [{ text: "Ok" }],
                {
                    cancelable: true
                }
            )
            console.log(error)
        })

    } catch (e) {
        setLoading(false)
        setSuccess(false)
        alert(
            "Error!",
            `Terjadi kesalahan! ${e.message}`,
            [{ text: "Ok" }],
            {
                cancelable: true
            }
        )
    }
}

export const removeDataPegawai = async () => {
    await AsyncStorage.removeItem('@nik')
    await AsyncStorage.removeItem('@nama')
    await AsyncStorage.removeItem('@jk')
    await AsyncStorage.removeItem('@kdLokasi')
    await AsyncStorage.removeItem('@lokasi')
    await AsyncStorage.removeItem('@isAdmin')
}

export const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1)
        n -= 1 // to make eslint happy
    }
    return new File([u8arr], filename, { type: mime })
}

