import React, { useCallback, useEffect, useState } from 'react';
import { Calendar, Text, Button, Icon, Input, Card, Datepicker, List, Modal, Divider, Avatar } from '@ui-kitten/components';
import { View, StyleSheet, Alert, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthProvider, useAuth } from '../../Context';
import { getToken, getDeviceIdentifier, removeDataPegawai, version } from "../functions/Helpers";
import { apiBaseUrl, apiUrl, bulans, haris } from '../functions/Constants';
import { TouchableWithoutFeedback } from '@ui-kitten/components/devsupport';
import Spinner from 'react-native-loading-spinner-overlay';
import alert from '../functions/alert';

const axios = require('axios')

const styles = StyleSheet.create({
    top: {
        marginTop: 40,
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 4,
    },
    containerBtn: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
        flex: 1,
    },
    show: {
        display: 'flex'
    },
    hide: {
        display: 'none'
    },
    redLabel: {
        // alignSelf: 'center',
        paddingVertical: 3,
        paddingHorizontal: 8,
        marginEnd: 5,
        backgroundColor: '#f65151',
        color: 'white',
        borderRadius: 5,
        fontSize: 12
    },
    yellowLabel: {
        // alignSelf: 'center',
        paddingVertical: 3,
        paddingHorizontal: 8,
        marginEnd: 5,
        backgroundColor: '#e9dc22',
        color: 'black',
        borderRadius: 5,
        fontSize: 12
    },
    greenLabel: {
        alignSelf: 'center',
        paddingVertical: 3,
        paddingHorizontal: 8,
        marginEnd: 5,
        backgroundColor: '#4ae419',
        color: 'black',
        borderRadius: 5,
        fontSize: 12
    },
    greyLabel: {
        // alignSelf: 'center',
        paddingVertical: 3,
        paddingHorizontal: 8,
        marginEnd: 5,
        backgroundColor: 'lightgrey',
        color: 'black',
        borderRadius: 5,
        fontSize: 12
    },
    dayContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        aspectRatio: 1,
    },
});

const BackIcon = (props) => (
    <Icon name='arrow-left-outline' style={{ width: 25, height: 25 }} fill="blue" />
);

const CloseIcon = (props) => (
    <Icon name='close-outline' style={{ width: 25, height: 25 }} fill="red" />
);

const ImageIcon = (props) => (
    <Icon name='image-outline' style={{ width: 25, height: 25 }} fill="blue" />
);

const LabelWaiting = () => (<Text style={styles.greyLabel}>Belum Disetujui</Text>)
const LabelApproved = () => (<Text style={styles.greenLabel}>Diterima</Text>)
const LabelRejected = () => (<Text style={styles.redLabel}>Ditolak</Text>)

const PersetujuanAbsen = ({ navigation, route }) => {

    const [date, setDate] = useState(new Date())
    const [showCalendar, setShowCalendar] = useState(false)
    const [loading, setLoading] = useState(false)
    const [listAbsen, setListAbsen] = useState([])
    const [dateNeedsApproval, setDateNeedsApproval] = useState([])
    const [spinnerShow, setSpinnerShow] = React.useState(false)
    const [finishLoadingTanggal, setFinishLoadingTanggal] = React.useState(false)
    const [modalVisible, setModalVisible] = React.useState(false);
    const [itemModal, setItemModal] = React.useState(null);

    useEffect(() => {
        const curDate = new Date()
        getTanggalButuhPersetujuan()
        getButuhPersetujuan(curDate)
    }, [])

    const getTanggalButuhPersetujuan = async () => {
        try {
            setFinishLoadingTanggal(false)
            setSpinnerShow(true)

            const appid = await getDeviceIdentifier();
            await getToken();
            const token = await AsyncStorage.getItem('@token');
            const nik = await AsyncStorage.getItem('@nik');
            const result = await axios.post(`${apiUrl}absen/get_tanggal_butuh_persetujuan`, {
                nik: nik,
            }, {
                headers: {
                    'x-token': token,
                    'x-appid': appid,
                    'x-version': version
                }
            })

            const data = result.data
            if (parseInt(data.metadata.code) == 200) {
                setDateNeedsApproval(data.response.list_tanggal)
            } else {
                alert(
                    "Gagal",
                    data.metadata.message,
                    [{ text: "Ok" }], { cancelable: true }
                )
            }

            setSpinnerShow(false)
            setFinishLoadingTanggal(true)
        } catch (e) {
            console.log(e);
            setFinishLoadingTanggal(true)
            setSpinnerShow(false)
            alert(
                "Gagal",
                "Terjadi kesalahan harap periksa jaringan internet anda",
                [{ text: "Ok" },
                {
                    text: 'Detil',
                    onPress: () => {
                        navigation.navigate('ErrorDetailScreen', { error: JSON.stringify(e, null, 2) })
                    }
                }], { cancelable: true }
            )
        }

    }

    const getButuhPersetujuan = async (selectedDate) => {
        try {
            setLoading(true)
            setSpinnerShow(true)
            const tgl = `${selectedDate.getFullYear()}-${("0" + (selectedDate.getMonth() + 1)).slice(-2)}-${("0" + selectedDate.getDate()).slice(-2)}`

            const appid = await getDeviceIdentifier();
            await getToken();
            const token = await AsyncStorage.getItem('@token');
            const nik = await AsyncStorage.getItem('@nik');
            const result = await axios.post(`${apiUrl}absen/get_butuh_persetujuan`, {
                nik: nik,
                tgl: tgl
            }, {
                headers: {
                    'x-token': token,
                    'x-appid': appid,
                    'x-version': version
                }
            })

            const data = result.data
            if (parseInt(data.metadata.code) == 200) {
                setListAbsen(data.response.list_absen)
            } else {
                alert(
                    "Gagal",
                    data.metadata.message,
                    [{ text: "Ok" }], { cancelable: true }
                )
            }

            setLoading(false)
            setSpinnerShow(false)
        } catch (e) {
            console.log(e);
            setLoading(false)
            setSpinnerShow(false)
            alert(
                "Gagal",
                "Terjadi kesalahan harap periksa jaringan internet anda",
                [{ text: "Ok" },
                {
                    text: 'Detil',
                    onPress: () => {
                        navigation.navigate('ErrorDetailScreen', { error: JSON.stringify(e, null, 2) })
                    }
                }], { cancelable: true }
            )
        }
    }

    const persetujuan = async (idAbsen, status, tanggal) => {
        try {
            setSpinnerShow(true)

            const appid = await getDeviceIdentifier();
            await getToken();
            const token = await AsyncStorage.getItem('@token');
            const nik = await AsyncStorage.getItem('@nik');
            const result = await axios.post(`${apiUrl}absen/persetujuan`, {
                nik: nik,
                id_absen: idAbsen,
                status: status
            }, {
                headers: {
                    'x-token': token,
                    'x-appid': appid,
                    'x-version': version
                }
            })

            const data = result.data
            if (parseInt(data.metadata.code) == 200) {
                getTanggalButuhPersetujuan();
                getButuhPersetujuan(new Date(tanggal))
            } else {
                alert(
                    "Gagal",
                    data.metadata.message,
                    [{ text: "Ok" }], { cancelable: true }
                )
            }

            setModalVisible(false)
            setSpinnerShow(false)
        } catch (e) {
            console.log(e)
            setModalVisible(false)
            setSpinnerShow(false)
            alert(
                "Gagal",
                "Terjadi kesalahan harap periksa jaringan internet anda",
                [{ text: "Ok" },
                {
                    text: 'Detil',
                    onPress: () => {
                        navigation.navigate('ErrorDetailScreen', { error: JSON.stringify(e, null, 2) })
                    }
                }], { cancelable: true }
            )
        }
    }

    const renderItemHeader = (item) => {
        return (
            <View
                style={{ display: "flex", flexDirection: 'row', marginHorizontal: 8, marginVertical: 5 }}>
                <Text style={{ textAlignVertical: 'center', marginEnd: 10 }}>{haris[parseInt(item.dw) - 1]}, {item.tanggal}</Text>
                <View style={{ display: 'flex', flexDirection: 'row', flex: 1, alignContent: 'flex-end' }}>
                    {item.diluar_jam ? <Text style={styles.yellowLabel}>Diluar Jam</Text> : <></>}
                    {item.diluar_wilayah ? <Text style={styles.yellowLabel}>Diluar GPS</Text> : <></>}
                </View>
            </View>
        )
    }
    const renderItem = ({ item, index }) => {

        return (
            <Card
                onPress={() => {
                    setItemModal(item)
                    setModalVisible(true)
                }}
                header={() => renderItemHeader(item)} style={{ marginTop: 6 }} status={item.approved === null ? "warning" : (item.approved === "1" ? "primary" : "danger")}>
                <View style={{ display: 'flex', flexDirection: 'row', alignContent: 'flex-start' }}>
                    <View style={{ flex: 1, display: "flex", flexDirection: 'column' }}>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                            {item.approved === null ?
                                <LabelWaiting />
                                : (item.approved === "1" ?
                                    <LabelApproved />
                                    : <LabelRejected />
                                )
                            }
                        </View>
                        <Text>{item.nik}</Text>
                        <Text>{item.nama}</Text>
                    </View>
                    <Text style={{ flex: 1, textAlign: "center" }}>Masuk{"\n"}{item.masuk ? item.masuk : "-"}</Text>
                    <Text style={{ flex: 1, textAlign: "center" }}>Keluar{"\n"}{item.keluar ? item.keluar : "-"}</Text>
                </View>
            </Card>
        )
    };

    const ModalApproval = () => {
        return (
            <Modal
                visible={modalVisible}
                backdropStyle={{
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}
                onBackdropPress={() => setModalVisible(false)}
                style={{ width: "90%" }}>
                <Card>
                    <TouchableOpacity
                        style={{ alignSelf: 'flex-end' }}
                        onPress={() => {
                            setModalVisible(false)
                        }}>
                        <CloseIcon />
                    </TouchableOpacity>
                    <Divider style={{ marginBottom: 8 }} />
                    <View style={{ display: "flex", flexDirection: 'column' }}>
                        <View style={{ flex: 1, display: 'flex', flexDirection: 'row', alignContent: 'flex-start', marginBottom: 8 }}>
                            <View style={{ flex: 1, display: "flex", flexDirection: 'column' }}>
                                <View style={{ display: 'flex', flexDirection: 'row' }}>
                                    {itemModal?.approved === null ?
                                        <LabelWaiting />
                                        : (itemModal?.approved === "1" ?
                                            <LabelApproved />
                                            : <LabelRejected />
                                        )
                                    }
                                </View>
                                <Text>{itemModal?.nik}</Text>
                                <Text>{itemModal?.nama}</Text>
                            </View>
                            <View style={{ flex: 1, display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
                                <Text style={{ textAlign: "center" }}>
                                    Masuk{"\n"}
                                    {itemModal?.masuk ? itemModal?.masuk : "-"}
                                </Text>
                                {
                                    itemModal && itemModal.masuk ?
                                        <Text style={{ textAlign: 'center', display: 'flex', flexDirection: 'row' }}>
                                            <TouchableOpacity
                                                // style={{ marginEnd: '50%', marginStart: "50%" }}
                                                onPress={() => {
                                                    setModalVisible(false)
                                                    navigation.navigate('ImageScreen', { imageUrl: `${apiBaseUrl}${itemModal.foto_masuk}` });
                                                }}
                                            >
                                                <ImageIcon />
                                            </TouchableOpacity>
                                        </Text>
                                        : <></>
                                }
                            </View>
                            <View style={{ flex: 1 }}>
                                <Text style={{ textAlign: "center" }}>
                                    Keluar{"\n"}
                                    {itemModal?.keluar ? itemModal?.keluar : "-"}
                                </Text>
                                {
                                    itemModal && itemModal.keluar ?
                                        <Text style={{ textAlign: 'center', display: 'flex', flexDirection: 'row' }}>
                                            <TouchableOpacity
                                                // style={{ marginEnd: '50%', marginStart: "50%" }}
                                                onPress={() => {
                                                    setModalVisible(false)
                                                    navigation.navigate('ImageScreen', { imageUrl: `${apiBaseUrl}${itemModal.foto_pulang}` });
                                                }}
                                            >
                                                <ImageIcon />
                                            </TouchableOpacity>
                                        </Text>
                                        : <></>
                                }
                            </View>
                        </View>
                        <View style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                            <Button
                                style={{ flex: 1, marginEnd: 4 }}
                                appearance="outline"
                                status="success"
                                onPress={() => {
                                    persetujuan(itemModal?.id_absen, 1, itemModal?.date)
                                }}
                            >
                                TERIMA
                            </Button>
                            <Button
                                style={{ flex: 1, marginStart: 4 }}
                                appearance="outline"
                                status="danger"
                                onPress={() => {
                                    persetujuan(itemModal?.id_absen, 0, itemModal?.date)
                                }}
                            >
                                TOLAK
                            </Button>
                        </View>
                    </View>
                </Card>
            </Modal >
        )
    }

    const DayCell = ({ date }, style) => {
        const tgl = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`
        const needsApproval = dateNeedsApproval.includes(tgl);
        const bg = needsApproval ? { backgroundColor: "rgba(216,64,58,0.3)" } : {};

        return (
            <View
                style={[styles.dayContainer, style.container, bg]}>
                <Text style={style.text}>{`${date.getDate()}`}</Text>
            </View>
        )
    }


    return (
        <View style={{ backgroundColor: '#fff', width: "100%", height: '100%' }}>
            <ModalApproval />
            <Spinner
                visible={spinnerShow}
                textContent={'Loading...'}
                textStyle={{ color: "#FFF" }}
            />
            <View style={{ marginHorizontal: 8, marginTop: 8 }}>
                <Text>Pilih tanggal absen: </Text>
                <Button
                    appearance="outline"
                    status="primary"
                    style={{ width: "50%" }}
                    onPress={() => {
                        setShowCalendar(!showCalendar)
                    }}>
                    {(date.getDate() + " " + bulans[date.getMonth()]) + " " + date.getFullYear()}
                </Button>

                {
                    finishLoadingTanggal ?
                        <Calendar
                            style={showCalendar ? styles.show : styles.hide}
                            date={date}
                            visibility={showCalendar}
                            renderDay={DayCell}
                            onSelect={nextDate => {
                                setDate(nextDate)
                                setShowCalendar(false)
                                getButuhPersetujuan(nextDate)
                            }}
                            min={new Date("2023-01-01")}

                        />
                        : <></>
                }

            </View>

            {
                listAbsen.length ?
                    <List
                        style={{ paddingHorizontal: 8, marginVertical: 10 }}
                        data={listAbsen}
                        onRefresh={() => {
                            getTanggalButuhPersetujuan()
                            getButuhPersetujuan(date)
                        }}
                        refreshing={loading}
                        renderItem={renderItem}
                    />
                    : <Text style={{ textAlign: 'center', marginTop: 20 }}>TIDAK ADA DATA</Text>
            }
        </View>
    )
};

export default PersetujuanAbsen;