import React, { useState } from 'react';
import { Input, Text, Button, Modal, Card } from '@ui-kitten/components';
import { View, StyleSheet, Image, Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useAuth } from '../../Context';
import * as Location from 'expo-location';
import alert from '../functions/alert';
import { Camera } from 'expo-camera';


const styles = StyleSheet.create({
    container: {
        marginTop: 35,
        marginBottom: 20,
        marginLeft: 20,
        marginRight: 20,
        flex: 1,
    },
    logo: {
        width: '80%',
        height: '80%',
        alignSelf: 'center'
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
});



const RequestPermission = () => {
    const {
        setPermissionAsked
    } = useAuth();

    const getPermission = async () => {
        alert(
            "Permintaan Perizinan",
            "Aplikasi presensi mobile ini memerlukan akses lokasi untuk dapat mencatat lokasi tempat Anda melakukan absensi.\nHarap berikan izin akses lokasi pada browser.",
            [{
                text: "OK",
                onPress: async () => {
                    // await AsyncStorage.setItem('@permissionAsked', '1')
                    // setPermissionAsked(true)
                    let permission;
                    permission = await Location.requestForegroundPermissionsAsync();
                    let statusPermissionLocation = false;
                    let statusPermissionCamera = false;
                    if (permission.status === 'granted') {
                        // alert(
                        //     "Peringatan",
                        //     "Izin lokasi ditolak.",
                        //     [{
                        //         text: "OK"
                        //     }], { cancelable: true }
                        // )
                        // return;
                        statusPermissionLocation = true;
                    }

                    permission = await Camera.requestCameraPermissionsAsync();
                    if (permission.status === 'granted') {
                        statusPermissionCamera = true
                    }

                    if (!statusPermissionCamera) {
                        alert(
                            "Peringatan",
                            "Izin kamera tidak didapatkan. Harap berikan akses dari pengaturan.",
                            [{
                                text: "OK"
                            }], { cancelable: true }
                        )
                        return;
                    }

                    if (!statusPermissionLocation) {
                        alert(
                            "Peringatan",
                            "Izin lokasi tidak didapatkan. Harap berikan akses dari pengaturan.",
                            [{
                                text: "OK"
                            }], { cancelable: true }
                        )
                        return;
                    }

                    setPermissionAsked(true)

                }
            }], { cancelable: false }
        )
    }

    return (
        <View style={styles.container}>
            <Image style={styles.logo} resizeMode='contain' source={require('../../assets/no-permission-text-512.png')} />

            <Text appearance="hint" status="danger">*Akses lokasi dan kamera dibutuhkan untuk melakukan absensi dengan aplikasi ini</Text>

            <Button
                style={{ marginTop: 10 }}
                appearance='filled'
                status='success'
                onPress={() => getPermission()}
            // disabled={loading}
            >BERIKAN AKSES</Button>
        </View>
    );

};

export default RequestPermission;