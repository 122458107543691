import React from 'react';

export const apiBaseUrl = "https://absen.tirtadaroy.co.id/api-absen-mobile-pdam-daroy/"
// export const apiBaseUrl = "https://api.usk.ac.id/api-absen-mobile-pdam-daroy/"
export const apiUrl = `${apiBaseUrl}index.php/web/`
export const username = "absen_daroy"
export const password = "lxgn4pg5T9^0%uQ1FZ4UbFpYT9tV6E5T"

export const bulans = [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember'
]

export const haris = [
    'Minggu',
    'Senin',
    'Selasa',
    'Rabu',
    'Kamis',
    'Jumat',
    'Sabtu'
]