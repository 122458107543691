import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text, Dimensions, Platform, TouchableOpacity, Alert, Image, BackHandler } from 'react-native';
import { Camera } from 'expo-camera';
import * as FaceDetector from 'expo-face-detector';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Location from 'expo-location';
import { apiUrl, bulans } from '../functions/Constants';
import { dataURLtoFile, getDeviceIdentifier, getToken, version } from '../functions/Helpers';
import Spinner from 'react-native-loading-spinner-overlay';
import { ImageType } from 'expo-camera/build/Camera.types';
import * as FileSystem from 'expo-file-system';
import alert from '../functions/alert';


const axios = require('axios')
const FormData = require('form-data')

const CameraAbsenKemarin = ({ navigation, route }) => {
    //  camera permissions
    const [hasCameraPermission, setHasCameraPermission] = useState(null);
    const [camera, setCamera] = useState(null);

    // Screen Ratio and image padding
    const [imagePadding, setImagePadding] = useState(0);
    // const [ratio, setRatio] = useState('4:3');  // default is 4:3
    const { height, width } = Dimensions.get('window');
    const screenRatio = height / width;
    const [isRatioSet, setIsRatioSet] = useState(false);
    const [faces, setFaces] = useState([]);
    const [jarak, setJarak] = useState(null);
    const [statusJarak, setStatusJarak] = useState(null);
    const [loading, setLoading] = useState(false);
    const [textLoading, setTextLoading] = useState("Loading");

    let checkArea = true;
    let takingPicture = false;
    const allowedRatio = ['1:1', '4:3', '11:9', '16:9']

    const backActionHandler = () => {
        console.log('back pressed')
        checkArea = false
        console.log(checkArea)
        navigation.navigate('HalamanAbsen')
        return true;
    }
    // on screen  load, ask for permission to use the camera
    useEffect(() => {
        async function getCameraStatus() {
            const { status } = await Camera.requestCameraPermissionsAsync();
            setHasCameraPermission(status == 'granted');

        }
        getCameraStatus();

        checkInArea();

        // Add event listener for hardware back button press on Android
        BackHandler.addEventListener("hardwareBackPress", backActionHandler);

        return () => {
            // clear/remove event listener
            BackHandler.removeEventListener("hardwareBackPress", backActionHandler);
            checkArea = false

        }
    }, []);

    // // set the camera ratio and padding.
    // // this code assumes a portrait mode screen
    // const prepareRatio = async () => {
    //     let desiredRatio = '4:3';  // Start with the system default
    //     // This issue only affects Android
    //     if (Platform.OS === 'android') {
    //         const ratios = await camera.getSupportedRatiosAsync();

    //         // Calculate the width/height of each of the supported camera ratios
    //         // These width/height are measured in landscape mode
    //         // find the ratio that is closest to the screen ratio without going over
    //         let distances = {};
    //         let realRatios = {};
    //         let minDistance = null;
    //         for (const ratio of ratios) {
    //             if (!allowedRatio.includes(ratio)) {
    //                 continue
    //             }
    //             const parts = ratio.split(':');
    //             const realRatio = parseInt(parts[0]) / parseInt(parts[1]);
    //             realRatios[ratio] = realRatio;
    //             // ratio can't be taller than screen, so we don't want an abs()
    //             const distance = screenRatio - realRatio;
    //             distances[ratio] = realRatio;
    //             if (minDistance == null) {
    //                 minDistance = ratio;
    //             } else {
    //                 if (distance >= 0 && distance < distances[minDistance]) {
    //                     minDistance = ratio;
    //                 }
    //             }
    //         }
    //         // set the best match
    //         desiredRatio = minDistance;
    //         //  calculate the difference between the camera width and the screen height
    //         const remainder = Math.floor(
    //             (height - realRatios[desiredRatio] * width) / 2
    //         );
    //         // set the preview padding and preview ratio
    //         setImagePadding(remainder);
    //         setRatio(desiredRatio);
    //         // Set a flag so we don't do this 
    //         // calculation each time the screen refreshes
    //         setIsRatioSet(true);
    //     }
    // };

    // // the camera must be loaded in order to access the supported ratios
    // const setCameraReady = async () => {
    //     if (!isRatioSet) {
    //         await prepareRatio();
    //     }
    // };

    const checkInArea = async () => {
        console.log("check area");
        if (!checkArea) {
            return
        }
        let { status } = await Location.requestForegroundPermissionsAsync();
        if (status !== 'granted') {
            alert(
                "Gagal",
                "Izin lokasi tidak didapatkan",
                [{ text: "Ok" }], { cancelable: true }
            )
            navigation.pop()
            return
        }
        try {
            const appid = await getDeviceIdentifier();
            await getToken();
            var token = await AsyncStorage.getItem('@token')

            const location = await Location.getCurrentPositionAsync({ accuracy: Location.Accuracy.High, timeInterval: 1 })

            if (location.mocked) {
                alert(
                    "Gagal",
                    "Harap untuk tidak menggunakan lokasi palsu!",
                    [{ text: "Ok" }], { cancelable: true }
                )
                return
            }

            const nik = await AsyncStorage.getItem('@nik')

            const result = await axios.post(`${apiUrl}absen/in_area`, {
                nik: nik,
                lat: location.coords.latitude,
                lon: location.coords.longitude,
            }, {
                headers: {
                    'x-token': token,
                    'x-appid': appid,
                    'x-version': version
                }
            })

            if (!checkArea) {
                return
            }
            const data = result.data
            setJarak(data.response.jarak)
            setStatusJarak(data.response.status)

            setTimeout(() => {
                checkInArea()
            }, 3000)

        } catch (e) {
            alert(
                "Gagal",
                "Terjadi kesalahan, harap coba lagi",
                [{ text: "Ok" },
                {
                    text: 'Detil',
                    onPress: () => {
                        navigation.navigate('ErrorDetailScreen', { error: JSON.stringify(e, null, 2) })
                    }
                }], { cancelable: true }
            )
            console.log(e)
        }
    }

    const doAbsen = async () => {
        try {
            if (!camera) return
            let { status } = await Location.requestForegroundPermissionsAsync();
            if (status !== 'granted') {
                alert(
                    "Gagal",
                    "Izin lokasi tidak didapatkan",
                    [{ text: "Ok" }], { cancelable: true }
                )
                navigation.pop()
                return
            }
            if (hasCameraPermission === null || hasCameraPermission === false) {
                return
            }
            // if (faces.length != 1) {
            //     alert(
            //         "Gagal",
            //         "Harus ada tepat 1 wajah terdeteksi!",
            //         [{ text: "Ok" }], { cancelable: true }
            //     )
            //     return
            // }
            setLoading(true)
            setTextLoading('Mengambil foto')
            takingPicture = true;
            setTimeout(() => {
                if (takingPicture) {
                    alert(
                        "Gagal",
                        "Proses terlalu lama. Harap coba lagi.",
                        [{
                            text: "Ok",
                            // onPress: () => {
                            //     checkArea = false
                            //     navigation.navigate('HalamanAbsen')
                            // }
                        }], { cancelable: false }
                    )
                    setLoading(false);
                    navigation.navigate('HalamanAbsen')
                }
            }, 3000);
            const photo = await camera.takePictureAsync({ quality: 0.3, imageType: ImageType.png, skipProcessing: true });
            takingPicture = false;

            const appid = await getDeviceIdentifier();
            const nik = await AsyncStorage.getItem('@nik')
            setTextLoading('Mengambil token')
            await getToken();
            var token = await AsyncStorage.getItem('@token')
            setTextLoading('Mengambil posisi saat ini')
            const location = await Location.getCurrentPositionAsync({ accuracy: Location.Accuracy.High, timeInterval: 1 })

            // const fileInfo = await FileSystem.getInfoAsync(photo.uri);

            const uri = photo.uri

            const file = dataURLtoFile(uri, 'foto-absen')
            const ext_arr = file.type.split('/');

            const fd = new FormData();
            fd.append('foto', file, file.name + "." + ext_arr[ext_arr.length - 1])
            fd.append('nik', nik)
            fd.append('lat', location.coords.latitude)
            fd.append('lon', location.coords.longitude)
            fd.append('tanggal', route.params.tanggal)

            setTextLoading('Mengupload foto')
            await axios
                .post(`${apiUrl}absen/absen_kemarin`, fd, {
                    // onUploadProgress: progressEvent => setTextLoading(`Terupload: ${(Math.round((progressEvent.loaded/fileInfo.size) * 100)).toFixed(2)}%`),
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                        'x-token': token,
                        'x-appid': appid,
                        'x-version': version
                    }
                }).then(function (res) {
                    const data = res.data;
                    console.log(data);
                    setLoading(false);
                    setTextLoading('Proses selesai')
                    if (parseInt(data.metadata.code) == 200) {
                        let msg = "Absen Berhasil"
                        let arrApproval = []
                        if (!data.response.di_area) {
                            arrApproval.push('berada di luar area')
                        }
                        if (!data.response.di_waktu) {
                            arrApproval.push('berada di luar waktu')
                        }

                        if (arrApproval.length > 0) {
                            msg = `${msg}. Harap tunggu persetujuan admin karena kamu ${arrApproval.join(' dan ')}`
                        }
                        alert(
                            "Sukses",
                            msg,
                            [{
                                text: "Ok",
                                onPress: () => {
                                    checkArea = false
                                    navigation.pop()
                                }
                            }], { cancelable: true }
                        )
                    } else {
                        alert(
                            "Gagal",
                            data.metadata.message,
                            [{ text: "Ok" }], { cancelable: true }
                        )
                    }

                })
        } catch (e) {
            alert(
                "Gagal",
                "Terjadi kesalahan, harap coba lagi.",
                [{ text: "Ok" },
                {
                    text: 'Detil',
                    onPress: () => {
                        navigation.navigate('ErrorDetailScreen', { error: JSON.stringify(e, null, 2) })
                    }
                }], { cancelable: true }
            )
            console.log(e)
            setLoading(false);
            setTextLoading('Proses selesai')
        }


    }

    const handleFacesDetected = (result) => {
        // alert(
        //     "Sukses",
        //     "Wajah teridentifikasi",
        //     [{ text: "Ok" }], { cancelable: true }
        // )
        // console.log(result)
        setFaces(result.faces)
    }

    const curDate = new Date();
    const date = `${curDate.getDate()} ${bulans[curDate.getMonth()]} ${curDate.getFullYear()} ${curDate.getHours()}:${curDate.getMinutes()}`

    if (hasCameraPermission === null) {
        return (
            <View style={styles.information}>
                <Text>Waiting for camera permissions</Text>
            </View>
        );
    } else if (hasCameraPermission === false) {
        return (
            <View style={styles.information}>
                <Text>No access to camera</Text>
            </View>
        );
    } else {
        return (
            <View style={styles.container}>
                <Spinner
                    visible={loading}
                    textContent={textLoading}
                    textStyle={{ color: "#FFF" }}
                />
                <Camera
                    style={[styles.cameraPreview, { marginTop: imagePadding, marginBottom: imagePadding }]}
                    // onCameraReady={setCameraReady}
                    // ratio={ratio}
                    ref={(ref) => {
                        setCamera(ref);
                    }}
                    type={Camera.Constants.Type.front}
                    onFacesDetected={handleFacesDetected}
                    faceDetectorSettings={{
                        mode: FaceDetector.FaceDetectorMode.accurate,
                        detectLandmarks: FaceDetector.FaceDetectorLandmarks.none,
                        runClassifications: FaceDetector.FaceDetectorClassifications.none,
                        minDetectionInterval: 500,
                        tracking: true,
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            flexDirection: 'column'
                        }}>
                        <View style={{
                            paddingTop: 30,
                            flex: 3,
                            backgroundColor: 'rgba(0,0,0,0.3)'
                        }}>
                            <Text style={{ color: 'white', flex: 1, textAlign: 'center', textAlignVertical: 'center', fontSize: 20 }}>
                                Absen Keluar Tanggal {route.params.tanggalDisplay}
                                {"\n"}
                                {/* Wajah terdeteksi: {faces.length}
                                {"\n"} */}
                                {
                                    statusJarak != null ?
                                        <Text style={statusJarak ? styles.textDiArea : styles.textDiluarArea}>
                                            Anda berada {statusJarak ? "di dalam" : "di luar"} area absen.{"\n"}Jarak ke titik absen terdekat: {jarak}
                                        </Text> : <></>
                                }
                            </Text>
                            <Text style={{ color: 'white', flex: 1, textAlign: 'center', fontSize: 15 }}>
                                {date}
                            </Text>


                        </View>
                        <View
                            style={{
                                flexDirection: 'row',
                                flex: 10,
                                width: '100%',
                                height: '80%',
                                padding: 20,
                                justifyContent: 'space-between'
                            }}>
                            <View
                                style={{
                                    alignSelf: 'center',
                                    flex: 1,
                                    alignItems: 'center'
                                }}
                            >
                                <Image
                                    style={{
                                        width: 300,
                                        height: 300,
                                        alignSelf: 'center',
                                        marginHorizontal: 20
                                    }}
                                    source={require('../../assets/camera-grid.png')}
                                />

                            </View>
                        </View>
                        <View
                            style={{
                                flexDirection: 'row',
                                flex: 1,
                                width: '100%',
                                padding: 20,
                                justifyContent: 'space-between'
                            }}>
                            <View
                                style={{
                                    alignSelf: 'center',
                                    flex: 1,
                                    alignItems: 'center'
                                }}
                            >
                                <TouchableOpacity
                                    onPress={doAbsen}
                                    style={{
                                        width: 70,
                                        height: 70,
                                        bottom: 0,
                                        borderRadius: 50,
                                        backgroundColor: '#fff'
                                    }}
                                />
                            </View>
                        </View>
                    </View>
                </Camera>
            </View >
        );
    }
}

const styles = StyleSheet.create({
    information: {
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
    },
    container: {
        flex: 1,
        backgroundColor: '#000',
        justifyContent: 'center',
        textAlign: 'center'
    },
    cameraPreview: {
        flex: 1,
    },
    textDiArea: { color: 'white', flex: 1, textAlign: 'center', textAlignVertical: 'center', fontSize: 15 },
    textDiluarArea: { color: 'yellow', flex: 1, textAlign: 'center', textAlignVertical: 'center', fontSize: 15 }
});

export default CameraAbsenKemarin