import React from "react"
import { Button, Text } from "@ui-kitten/components"
import { Alert, SafeAreaView, ScrollView } from "react-native"
import * as Clipboard from 'expo-clipboard';

const ErrorDetailScreen = ({ navigation, route }) => {
    return (
        <SafeAreaView style={{ margin: 8 }}>
            <ScrollView>
                <Button
                    appearance="outline"
                    onPress={async () => {
                        await Clipboard.setStringAsync(route.params.error)
                    }}
                >
                    Salin Error
                </Button>
                <Text>{route.params.error}</Text>
            </ScrollView>
        </SafeAreaView>
    )
};

export default ErrorDetailScreen;