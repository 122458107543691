import React, { useEffect } from 'react';
import { Input, Text, Button } from '@ui-kitten/components';
import { View, StyleSheet, Image, Alert, BackHandler } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthProvider, useAuth } from '../../Context';
import { doRegisterDevice, getToken, getDeviceIdentifier } from "../functions/Helpers";
import alert from '../functions/alert';

// const axios = require('axios');
// const md5 = require('md5');

const styles = StyleSheet.create({
    container: {
        marginTop: 35,
        marginBottom: 20,
        marginLeft: 20,
        marginRight: 20,
        flex: 1
    }
});


const Register = () => {

    const [nik, setNik] = React.useState('');
    // const [password, setPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const { setDeviceRegistered, setLoggedIn } = useAuth();

    const confirmRegister = () => {
        if (!nik) {
            alert(
                "Peringatan!",
                "NIK tidak boleh kosong!",
                [{ text: "Ok" }],
                {
                    cancelable: true
                }
            )
        } else {
            alert(
                "Anda yakin?",
                `Anda yakin ingin mendaftarkan NIK ${nik} pada perangkat ini?\nSatu perangkat HANYA dapat didaftarkan SATU KALI!`,
                [
                    {
                        text: "Yakin",
                        onPress: registerDevice
                    },
                    {
                        text: "Batal"
                    }
                ],
                {
                    cancelable: true
                }
            )
        }

    }

    const registerDevice = () => {

        setLoading(true);

        (async () => {
            try {
                const appid = await getDeviceIdentifier();
                await doRegisterDevice(appid, nik, setDeviceRegistered, setLoading, setLoggedIn);
                // setLoading(false);
            } catch (error) {
                alert(
                    "Error!",
                    "Terjadi Kesalahan!",
                    [{ text: "Ok" }],
                    {
                        cancelable: true
                    }
                )
                console.log(error);
                setLoading(false);
            }
        })()
    }


    return (
        <View style={styles.container}>
            <Text category="h6" style={{ fontWeight: 'bold', textAlign: 'center', marginTop: 20, marginBottom: 40 }}>DAFTARKAN PERANGKAT BARU</Text>

            <Input
                placeholder='Masukkan NIK pemilik perangkat'
                value={nik}
                keyboardType="default"
                onChangeText={nextValue => setNik(nextValue)} />

            <Text appearance="hint" status="danger">PERHATIAN! NIK pengguna perangkat hanya dapat diatur satu kali!</Text>

            <Button
                style={{ marginTop: 40 }}
                appearance='outline'
                status='primary'
                onPress={() => confirmRegister()}
                disabled={loading}>{loading ? "Harap tunggu..." : "SIMPAN"}</Button>
        </View>
    );

};

export default Register;