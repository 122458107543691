import React from 'react';
import * as eva from '@eva-design/eva';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { ApplicationProvider, Layout, Text, IconRegistry } from '@ui-kitten/components';
import HalamanLogin from './src/screen/Login';
import HalamanAbsen from './src/screen/Absen';
import Register from './src/screen/RegisterDevice';
import RequestPermission from './src/screen/RequestLocationPermission';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthProvider, useAuth } from './Context';
import { getToken, getDeviceIdentifier, removeDataPegawai } from "./src/functions/Helpers";
import { Alert, Platform } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import CameraAbsen from './src/screen/CameraAbsen';
import RekapAbsen from './src/screen/RekapAbsen';
import ResetPassword from './src/screen/ResetPassword';
import CameraKunjungan from './src/screen/CameraKunjungan';
import CameraFoto from './src/screen/CameraFoto';
import { StatusBar } from 'expo-status-bar';
import PersetujuanAbsen from './src/screen/PersetujuanAbsen';
import ImageScreen from './src/screen/ImageViewer';
import CameraAbsenKemarin from './src/screen/CameraAbsenKemarin';
import ErrorDetailScreen from './src/screen/ErrorDetailScreen';
import alert from './src/functions/alert';

const Stack = createNativeStackNavigator();

export default () => {
    return (
        <>
            <IconRegistry icons={EvaIconsPack} />
            <ApplicationProvider {...eva} theme={eva.light}>
                <AuthProvider>
                    <Content />
                </AuthProvider>
                <StatusBar style="dark" />
            </ApplicationProvider>

        </>
        )
};


const Content = () => {
    const {
        loggedIn, setLoggedIn,
        deviceRegistered, setDeviceRegistered,
        permissionAsked, setPermissionAsked
    } = useAuth()


    React.useEffect(() => {

        (async () => {
            //check token
            try {
                await getToken();
                var token = await AsyncStorage.getItem('@token')
            } catch (e) {
                alert(
                    "Error!",
                    "Terjadi kesalahan!",
                    [{ text: "Ok" }],
                    {
                        cancelable: true
                    }
                )
            }

            // //check appid sudah terdaftar
            // try {
            //     var appid = await getDeviceIdentifier();

            //     console.log(appid)
            //     await checkDevice(appid, setDeviceRegistered)

            // } catch (e) {
            //     alert(
            //         "Error!",
            //         "Terjadi kesalahan!",
            //         [{ text: "Ok" }],
            //         {
            //             cancelable: true
            //         }
            //     )
            //     console.log(e)
            // }

            try {
                const value = await AsyncStorage.getItem('@nik')
                if (value !== null) {
                    setLoggedIn(true)
                } else {
                    setLoggedIn(false)
                }
            } catch (e) {
                setLoggedIn(false)

                await removeDataPegawai()

            }

            try {
                const asked = await AsyncStorage.getItem('@permissionAsked')
                if (asked !== null) {
                    setPermissionAsked(true)
                } else {
                    setPermissionAsked(false)
                }
            } catch (e) {
                setPermissionAsked(false)
                console.log(e);
            }
        })()
        // getData();
    }, [])

    return (
        <>
            {
                permissionAsked ?
                //     deviceRegistered ?
                //         (
                            loggedIn ?
                                (
                                    <NavigationContainer>
                                        <Stack.Navigator initialRouteName="HalamanAbsen">
                                            <Stack.Screen name="HalamanAbsen" component={HalamanAbsen} options={{ headerShown: false }} />
                                            <Stack.Screen name="RekapAbsen" component={RekapAbsen} options={{ headerTitle: "Rekap Absen" }} />
                                            <Stack.Screen name="PersetujuanAbsen" component={PersetujuanAbsen} options={{ headerTitle: "Persetujuan Absen" }} />
                                            <Stack.Screen name="CameraAbsen" component={CameraAbsen} options={{ headerTitle: "Foto Absen", unmountOnBlur: true }} />
                                            <Stack.Screen name="CameraAbsenKemarin" component={CameraAbsenKemarin} options={{ headerTitle: "Foto Absen Kemarin", unmountOnBlur: true }} />
                                            <Stack.Screen name="CameraKunjungan" component={CameraKunjungan} options={{ headerTitle: "Foto Kunjungan", unmountOnBlur: true }} />
                                            <Stack.Screen name="CameraFoto" component={CameraFoto} options={{ headerTitle: "Foto Awal", unmountOnBlur: true }} />
                                            <Stack.Screen name="ImageScreen" component={ImageScreen} options={{ headerShown: false }} />
                                            <Stack.Screen name="ErrorDetailScreen" component={ErrorDetailScreen} options={{ headerTitle: "Detil Error" }} />
                                        </Stack.Navigator>
                                    </NavigationContainer>

                                ) :
                                (
                                    <NavigationContainer>
                                        <Stack.Navigator initialRouteName="HalamanLogin">
                                            <Stack.Screen name="HalamanLogin" component={HalamanLogin} options={{ headerShown: false }} />
                                            <Stack.Screen name="ResetPassword" component={ResetPassword} options={{ headerTitle: "Atur Password" }} />
                                            <Stack.Screen name="ErrorDetailScreen" component={ErrorDetailScreen} options={{ headerTitle: "Detil Error" }} />
                                        </Stack.Navigator>
                                    </NavigationContainer>
                                )
                    //     ) :
                    //     <Register />
                    :
                    <RequestPermission />
            }
        </>
    )
}
