import React from 'react';
import { TouchableWithoutFeedback, ScrollView } from 'react-native';
import { Input, Text, Button, Icon } from '@ui-kitten/components';
import { View, StyleSheet, Image, Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthProvider, useAuth } from '../../Context';
import { getToken, getDeviceIdentifier, removeDataPegawai, version } from "../functions/Helpers";
import { apiUrl } from '../functions/Constants';
import alert from '../functions/alert';


const axios = require('axios');
const md5 = require('md5');

const styles = StyleSheet.create({
    container: {
        paddingTop: 35,
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
        flex: 1,
        backgroundColor: '#fff'
    },
    topSide: {
        width: '80%',
        height: '55%',
        alignSelf: 'center'
    },
    botSide: {
        marginTop: 20,
        width: '100%',
        height: '50%'
    },
    logo: {
        marginTop: 10,
        marginBottom: 100,
        marginRight: 10,
        marginLeft: 10,
        width: '100%',
        height: '100%',
        alignSelf: 'center'
    },
});


const storeData = async (nik, nama, kdLokasi, lokasi, jk, isAdmin) => {
    try {
        const setNik = AsyncStorage.setItem('@nik', nik)
        const setNama = AsyncStorage.setItem('@nama', nama)
        const setKdLokasi = AsyncStorage.setItem('@kdLokasi', kdLokasi)
        const setLokasi = AsyncStorage.setItem('@lokasi', lokasi)
        const setJK = AsyncStorage.setItem('@jk', jk)
        const setIsAdmin = AsyncStorage.setItem('@isAdmin', isAdmin)

        Promise.all([setNik, setNama, setKdLokasi, setLokasi, setJK, setIsAdmin])

    } catch (e) {
        console.log("Something went wrong");
    }
}

const HalamanLogin = ({ navigation, route }) => {

    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [secureTextEntry, setSecureTextEntry] = React.useState(true);
    const {
        setLoggedIn,
        setDeviceRegistered
    } = useAuth()

    const toggleSecureEntry = () => {
        setSecureTextEntry(!secureTextEntry);
    };

    const renderIcon = (props) => (
        <TouchableWithoutFeedback onPress={toggleSecureEntry}>
            <Icon width={25} height={25} name={secureTextEntry ? 'eye' : 'eye-off'} fill="blue" />
        </TouchableWithoutFeedback>
    );

    const loginPegawai = () => {
        if (!username || !password) {
            alert(
                "Peringatan!",
                "Username dan Password tidak boleh kosong!",
                [{ text: "Ok" }],
                {
                    cancelable: true
                }
            )
        }

        setLoading(true);

        (async () => {
            try {
                const appid = await getDeviceIdentifier();
                await getToken();
                var token = await AsyncStorage.getItem('@token')

                const result = await axios.post(`${apiUrl}login`, {
                    nik: username,
                    password: password
                }, {
                    headers: {
                        'x-token': token,
                        'x-appid': appid,
                        'x-version': version
                    }
                })
                setLoading(false);
                const data = result.data
                console.log(data);
                if (parseInt(data.metadata.code) == 403) {
                    alert(
                        "Login gagal!",
                        data.metadata.message,
                        [{ text: "Ok" }],
                        {
                            cancelable: true
                        }
                    )
                    setDeviceRegistered(false)
                } else if (parseInt(data.metadata.code) == 200) {
                    if (data.response.status) {
                        const dataPegawai = data.response.data_pegawai;
                        console.log("Logged");
                        console.log(dataPegawai);
                        (async () => {
                            await storeData(dataPegawai.nik, dataPegawai.nama, dataPegawai.id_lokasi, dataPegawai.nama_lokasi, dataPegawai.jenis_kelamin, data.response.is_admin);
                        })();
                        setLoggedIn(true);
                    } else {
                        alert(
                            "Login gagal!",
                            data.response.message,
                            [{ text: "Ok" }],
                            {
                                cancelable: true
                            }
                        )
                    }
                } else {
                    //harus mendaftar akun dahulu
                    if (parseInt(data.metadata.code) == 404) {
                        setLoggedIn(false)
                        alert(
                            "Akun belum didaftarkan",
                            "Anda belum pernah mendaftarkan akun, harap daftrakan akun terlebih dahulu",
                            [{
                                text: "Ok",
                                onPress: () => {
                                    navigation.navigate('ResetPassword')
                                }
                            }],
                            {
                                cancelable: true
                            }
                        );
                    } else {
                        alert(
                            "Akun belum didaftarkan",
                            data.metadata.message,
                            [{ text: "Ok" }],
                            {
                                cancelable: true
                            }
                        );
                    }
                }
            } catch (error) {
                setLoading(false);
                alert(
                    "Terjadi Kesalahan!",
                    "Harap coba lagi, atau tutup dan buka kembali aplikasi.",
                    [{ text: "Ok" },
                    {
                        text: 'Detil',
                        onPress: () => {
                            navigation.navigate('ErrorDetailScreen', { error: JSON.stringify(error, null, 2) })
                        }
                    }],
                    {
                        cancelable: true
                    }
                );
            }
        })()
    }

    return (
        <View style={styles.container}>
            <View style={styles.topSide}>
                {/* <Text category="h3">Login Absen Mobile</Text> */}
                <Image style={styles.logo} source={require('../../assets/logo-pdam.png')} />
            </View>

            <ScrollView>
                <View style={styles.botSide}>
                    <Text appearance="hint">Username:</Text>
                    <Input
                        placeholder='Masukkan NIK'
                        value={username}
                        textContentType="username"
                        autoCapitalize='none'
                        onChangeText={nextValue => setUsername(nextValue)}
                    />
                    <Text appearance="hint" style={{ marginTop: 20 }}>Password:</Text>
                    <Input
                        placeholder='Masukkan Password'
                        value={password}
                        onChangeText={nextValue => setPassword(nextValue)}
                        secureTextEntry={secureTextEntry}
                        autoCapitalize="none"
                        accessoryRight={renderIcon}
                    />
                    <Button
                        style={{ marginTop: 40 }}
                        appearance='outline'
                        status='primary'
                        onPress={() => loginPegawai()}
                        disabled={loading}>{loading ? "Harap tunggu..." : "MASUK"}</Button>
                    <Button
                        style={{ marginTop: 10 }}
                        appearance='outline'
                        onPress={() => { navigation.navigate('ResetPassword') }}
                        status='success'>Daftar Akun/Lupa Password</Button>
                </View>
            </ScrollView>

        </View>
    );

};

export default HalamanLogin;