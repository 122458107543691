import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text, Dimensions, Platform, TouchableOpacity, Alert, Image, BackHandler, Pressable } from 'react-native';
import { Camera } from 'expo-camera';
import { Button } from '@ui-kitten/components';
import * as FaceDetector from 'expo-face-detector';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { apiUrl } from '../functions/Constants';
import { dataURLtoFile, getDeviceIdentifier, getToken, version } from '../functions/Helpers';
import Spinner from 'react-native-loading-spinner-overlay';
import { ImageType } from 'expo-camera/build/Camera.types';
import * as FileSystem from 'expo-file-system';
import alert from '../functions/alert';


const axios = require('axios')
const FormData = require('form-data')


const CameraFoto = ({ navigation, route }) => {
    //  camera permissions
    const [hasCameraPermission, setHasCameraPermission] = useState(null);
    const [camera, setCamera] = useState(null);

    // Screen Ratio and image padding
    const [imagePadding, setImagePadding] = useState(0);
    // const [ratio, setRatio] = useState('4:3');  // default is 4:3
    const { height, width } = Dimensions.get('window');
    const screenRatio = height / width;
    const [isRatioSet, setIsRatioSet] = useState(false);
    const [faces, setFaces] = useState([]);
    const [loading, setLoading] = useState(null);
    const [pauseCamera, setPauseCamera] = useState(false)
    const [photo, setPhoto] = useState(null)
    const [textLoading, setTextLoading] = useState("Loading");

    const allowedRatio = ['1:1', '4:3', '11:9', '16:9']
    let takingPicture = false;

    // on screen  load, ask for permission to use the camera
    useEffect(() => {
        async function getCameraStatus() {
            const { status } = await Camera.requestCameraPermissionsAsync();
            setHasCameraPermission(status == 'granted');
        }
        getCameraStatus();

    }, []);

    // // set the camera ratio and padding.
    // // this code assumes a portrait mode screen
    // const prepareRatio = async () => {
    //     let desiredRatio = '4:3';  // Start with the system default
    //     // This issue only affects Android
    //     if (Platform.OS === 'android') {
    //         const ratios = await camera.getSupportedRatiosAsync();

    //         // Calculate the width/height of each of the supported camera ratios
    //         // These width/height are measured in landscape mode
    //         // find the ratio that is closest to the screen ratio without going over
    //         let distances = {};
    //         let realRatios = {};
    //         let minDistance = null;
    //         for (const ratio of ratios) {
    //             if (!allowedRatio.includes(ratio)) {
    //                 continue
    //             }
    //             const parts = ratio.split(':');
    //             const realRatio = parseInt(parts[0]) / parseInt(parts[1]);
    //             realRatios[ratio] = realRatio;
    //             // ratio can't be taller than screen, so we don't want an abs()
    //             const distance = screenRatio - realRatio;
    //             distances[ratio] = realRatio;
    //             if (minDistance == null) {
    //                 minDistance = ratio;
    //             } else {
    //                 if (distance >= 0 && distance < distances[minDistance]) {
    //                     minDistance = ratio;
    //                 }
    //             }
    //         }
    //         // set the best match
    //         desiredRatio = minDistance;
    //         //  calculate the difference between the camera width and the screen height
    //         const remainder = Math.floor(
    //             (height - realRatios[desiredRatio] * width) / 2
    //         );
    //         // set the preview padding and preview ratio
    //         setImagePadding(remainder);
    //         setRatio(desiredRatio);
    //         // Set a flag so we don't do this 
    //         // calculation each time the screen refreshes
    //         setIsRatioSet(true);
    //     }
    // };

    // // the camera must be loaded in order to access the supported ratios
    // const setCameraReady = async () => {
    //     if (!isRatioSet) {
    //         await prepareRatio();
    //     }
    // };

    const cancelPreview = async () => {
        await camera.resumePreview()
        setPauseCamera(false)
    }

    const pausePreview = async () => {
        // if (faces.length != 1) {
        //     alert(
        //         "Gagal",
        //         "Harus ada tepat 1 wajah terdeteksi!",
        //         [{ text: "Ok" }], { cancelable: true }
        //     )
        //     return
        // }

        setTextLoading('Mengambil foto')
        takingPicture = true;
        setTimeout(() => {
            if (takingPicture) {
                alert(
                    "Gagal",
                    "Proses terlalu lama. Harap coba lagi.",
                    [{
                        text: "Ok",
                        // onPress: () => {
                        //     checkArea = false
                        //     navigation.navigate('HalamanAbsen')
                        // }
                    }], { cancelable: false }
                )
                setLoading(false);
                navigation.navigate('HalamanAbsen')
            }
        }, 3000);
        const phototmp = await camera.takePictureAsync({ quality: 0.3, imageType: ImageType.png, skipProcessing: true });
        takingPicture = false;
        await setPhoto(phototmp)
        await camera.pausePreview()
        setPauseCamera(true)
    }

    const submitFoto = async () => {
        if (!camera) return

        setTextLoading('Memulai proses')
        setLoading(true)
        const appid = await getDeviceIdentifier();
        const nik = await AsyncStorage.getItem('@nik')
        setTextLoading('Mengambil token')
        await getToken();
        var token = await AsyncStorage.getItem('@token')

        const uri = photo.uri
        const fd = new FormData();

        const file = dataURLtoFile(uri, 'foto-absen')
        const ext_arr = file.type.split('/');

        fd.append('foto', file, file.name + "." + ext_arr[ext_arr.length - 1])
        fd.append('nik', nik)
        // const fileInfo = await FileSystem.getInfoAsync(photo.uri);

        setTextLoading('Mengupload foto')
        try {
            await axios
                .post(`${apiUrl}absen/submit_foto`, fd, {
                    // onUploadProgress: progressEvent => setTextLoading(`Terupload: ${(Math.round((progressEvent.loaded / fileInfo.size) * 100)).toFixed(2)}%`),
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                        'x-token': token,
                        'x-appid': appid,
                        'x-version': version
                    }
                }).then(function (res) {
                    const data = res.data;
                    console.log(data);
                    setTextLoading('Proses selesai')
                    setLoading(false);
                    if (parseInt(data.metadata.code) == 200) {
                        if (data.response.status) {
                            alert(
                                "Sukses",
                                "Foto berhasil disimpan",
                                [{
                                    text: "Ok",
                                    onPress: () => {
                                        navigation.navigate('HalamanAbsen')
                                    }
                                }], { cancelable: true }
                            )
                        } else {
                            alert(
                                "Gagal",
                                data.metadata.message,
                                [{ text: "Ok" }], { cancelable: true }
                            )
                        }
                    } else {
                        alert(
                            "Gagal",
                            data.metadata.message,
                            [{ text: "Ok" }], { cancelable: true }
                        )
                    }

                })
        } catch (e) {
            alert(
                "Gagal",
                "Harap coba lagi.",
                [{ text: "Ok" },
                {
                    text: 'Detil',
                    onPress: () => {
                        navigation.navigate('ErrorDetailScreen', { error: JSON.stringify(e, null, 2) })
                    }
                }], { cancelable: true }
            )
            console.log(e)
            setLoading(false);
            setTextLoading('Proses selesai')
        }


    }

    const handleFacesDetected = (result) => {
        setFaces(result.faces)
    }


    if (hasCameraPermission === null) {
        return (
            <View style={styles.information}>
                <Text>Waiting for camera permissions</Text>
            </View>
        );
    } else if (hasCameraPermission === false) {
        return (
            <View style={styles.information}>
                <Text>No access to camera</Text>
            </View>
        );
    } else {
        return (
            <View style={styles.container}>
                <Spinner
                    visible={loading}
                    textContent={textLoading}
                    textStyle={{ color: "#FFF" }}
                />
                <Camera
                    style={[styles.cameraPreview, { marginTop: imagePadding, marginBottom: imagePadding }]}
                    // onCameraReady={setCameraReady}
                    // ratio={ratio}
                    ref={(ref) => {
                        setCamera(ref);
                    }}
                    type={Camera.Constants.Type.front}
                    onFacesDetected={handleFacesDetected}
                    faceDetectorSettings={{
                        mode: FaceDetector.FaceDetectorMode.accurate,
                        detectLandmarks: FaceDetector.FaceDetectorLandmarks.none,
                        runClassifications: FaceDetector.FaceDetectorClassifications.none,
                        minDetectionInterval: 500,
                        tracking: true,
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            flexDirection: 'column'
                        }}>
                        <View style={{
                            flex: 4,
                            backgroundColor: 'rgba(0,0,0,0.3)'
                        }}>
                            <Text style={{ color: 'white', flex: 1, textAlign: 'center', textAlignVertical: 'center', fontSize: 20 }}>
                                Ambil Foto Awal Pegawai
                                {/* {"\n"}
                                Wajah terdeteksi: {faces.length} */}
                            </Text>
                        </View>
                        <View
                            style={{
                                flexDirection: 'row',
                                flex: 10,
                                width: '100%',
                                height: '80%',
                                padding: 20,
                                justifyContent: 'space-between'
                            }}>
                            <View
                                style={{
                                    alignSelf: 'center',
                                    flex: 1,
                                    alignItems: 'center'
                                }}
                            >
                                <Image
                                    style={{
                                        width: 300,
                                        height: 300,
                                        alignSelf: 'center',
                                        marginHorizontal: 20
                                    }}
                                    source={require('../../assets/camera-grid.png')}
                                />

                            </View>
                        </View>
                        <View
                            style={{
                                flexDirection: 'row',
                                flex: 1,
                                width: '100%',
                                padding: 20,
                                justifyContent: 'space-between'
                            }}>
                            <View
                                style={{
                                    alignSelf: 'center',
                                    flex: 1,
                                    alignItems: 'center'
                                }}
                            >
                                {
                                    pauseCamera ?
                                        <>
                                            <Button
                                                status='basic'
                                                onPress={cancelPreview}
                                                style={{ marginBottom: 8 }}
                                            >
                                                AMBIL ULANG
                                            </Button>
                                            <Button
                                                status="primary"
                                                onPress={submitFoto}
                                            >
                                                SIMPAN
                                            </Button>
                                        </>
                                        :
                                        <TouchableOpacity
                                            disabled={pauseCamera}
                                            onPress={pausePreview}
                                            style={styles.takePictureBtn}
                                        />
                                }


                            </View>
                        </View>
                    </View>
                </Camera>
            </View >
        );
    }
}

const styles = StyleSheet.create({
    information: {
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
    },
    container: {
        flex: 1,
        backgroundColor: '#000',
        justifyContent: 'center',
        textAlign: 'center'
    },
    cameraPreview: {
        flex: 1,
    },
    takePictureBtn: {
        width: 70,
        height: 70,
        bottom: 0,
        borderRadius: 50,
        backgroundColor: '#fff'
    },
});

export default CameraFoto;