import React, { useEffect, useState } from 'react';
import { Calendar, Text, Button, Icon, Input, Card, Datepicker, List } from '@ui-kitten/components';
import { View, StyleSheet, Alert, RefreshControl } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthProvider, useAuth } from '../../Context';
import { getToken, getDeviceIdentifier, removeDataPegawai, version } from "../functions/Helpers";
import { apiUrl, bulans } from '../functions/Constants';
import { TouchableWithoutFeedback } from '@ui-kitten/components/devsupport';
import Spinner from 'react-native-loading-spinner-overlay';
import alert from '../functions/alert';

const axios = require('axios')

const styles = StyleSheet.create({
    top: {
        marginTop: 40,
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 4,
    },
    containerBtn: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'row',
        flex: 1,
    },
    show: {
        display: 'flex'
    },
    hide: {
        display: 'none'
    },
    redLabel: {
        alignSelf: 'center',
        paddingVertical: 3,
        paddingHorizontal: 8,
        margin: 5,
        backgroundColor: '#f65151',
        color: 'white',
        borderRadius: 5,
        fontSize: 12
    },
    yellowLabel: {
        alignSelf: 'center',
        paddingVertical: 3,
        paddingHorizontal: 8,
        margin: 5,
        backgroundColor: '#e9dc22',
        color: 'black',
        borderRadius: 5,
        fontSize: 12
    },
    greenLabel: {
        alignSelf: 'center',
        paddingVertical: 3,
        paddingHorizontal: 8,
        margin: 5,
        backgroundColor: '#4ae419',
        color: 'black',
        borderRadius: 5,
        fontSize: 12
    }
});

const BackIcon = (props) => (
    <Icon name='arrow-left-outline' style={{ width: 25, height: 25 }} fill="blue" />
);


const RekapAbsen = ({ navigation, route }) => {

    const [date, setDate] = useState(new Date())
    const [showCalendar, setShowCalendar] = useState(false)
    const [loading, setLoading] = useState(false)
    const [rekap, setRekap] = useState([])

    useEffect(() => {
        const curDate = new Date()
        getRekapBulanan(curDate)
    }, [])

    const getRekapBulanan = async (selectedDate) => {
        try {
            setLoading(true)

            const appid = await getDeviceIdentifier();
            await getToken();
            const token = await AsyncStorage.getItem('@token');
            const nik = await AsyncStorage.getItem('@nik');
            const result = await axios.post(`${apiUrl}absen/rekap_absen_bulanan`, {
                nik: nik,
                bulan: selectedDate.getMonth() + 1,
                tahun: selectedDate.getFullYear()
            }, {
                headers: {
                    'x-token': token,
                    'x-appid': appid,
                    'x-version': version
                }
            })

            const data = result.data
            if (parseInt(data.metadata.code) == 200) {
                setRekap(data.response.rekap)
            } else {
                alert(
                    "Gagal",
                    data.metadata.message,
                    [{ text: "Ok" }], { cancelable: true }
                )
            }

            setLoading(false)
        } catch (e) {
            console.log(e);
            alert(
                'Gagal',
                'Terjadi kesalahan, harap coba lagi',
                [{ text: 'Ok' },
                {
                    text: 'Detil',
                    onPress: () => {
                        navigation.navigate('ErrorDetailScreen', { error: JSON.stringify(e, null, 2) })
                    }
                }],
                { cancelable: true }
            )
        }

    }

    const renderItemHeader = (item) => {
        return (
            <View
                style={{ display: "flex", flexDirection: 'row', marginHorizontal: 8, marginVertical: 5 }}>
                <Text style={{ textAlignVertical: 'center' }}>{item.hari}, {item.tanggal}</Text>
                <View style={{ display: 'flex', flexDirection: 'row', flex: 1, alignContent: 'flex-end' }}>
                    {item.jenis == "2" ? <Text style={styles.greenLabel}>{item.nama_jenis}</Text> : <></>}
                    {item.jenis == "1" && item.tidak_lengkap ? <Text style={styles.yellowLabel}>Tidak Lengkap</Text> : <></>}
                    {item.jenis == "1" && item.terlambat ? <Text style={styles.yellowLabel}>Terlambat</Text> : <></>}
                    {item.jenis == "1" && item.tanpa_status ? <Text style={styles.redLabel}>Tanpa Status</Text> : <></>}
                </View>
            </View>
        )
    }
    const renderItem = ({ item, index }) => (
        <Card header={() => renderItemHeader(item)} style={{ marginTop: 6 }} status={item.needs_approval ? (item.approved === null ? "warning" : (item.approved === "1" ? "primary" : "danger")) : "primary"}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Text style={{ flex: 1, textAlignVertical: 'center' }}>{item.nama_jenis}</Text>
                <Text style={{ flex: 1 }}>Masuk{"\n"}{item.masuk}</Text>
                <Text style={{ flex: 1 }}>Keluar{"\n"}{item.keluar}</Text>
            </View>
        </Card>
    );


    return (
        <View style={{ backgroundColor: '#fff', width: "100%", height: '100%' }}>
            <View style={{ marginHorizontal: 8, marginTop: 8 }}>
                <Text>Pilih bulan rekap: </Text>
                <Button
                    appearance="outline"
                    status="primary"
                    style={{ width: "50%" }}
                    onPress={() => {
                        setShowCalendar(!showCalendar)
                    }}>
                    {(bulans[date.getMonth()]) + " " + date.getFullYear()}
                </Button>

                <Calendar
                    style={showCalendar ? styles.show : styles.hide}
                    date={date}
                    visibility={showCalendar}
                    onSelect={nextDate => {
                        setDate(nextDate)
                        setShowCalendar(false)
                        getRekapBulanan(nextDate)
                    }}
                    min={new Date("2023-01-01")}

                />
            </View>

            <List
                style={{ paddingHorizontal: 8, marginVertical: 10 }}
                data={rekap}
                onRefresh={() => getRekapBulanan(date)}
                refreshing={loading}
                renderItem={renderItem}
            />
        </View>
    )
};

export default RekapAbsen;