import React, { useEffect } from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import { Input, Text, Button, Divider, Icon } from '@ui-kitten/components';
import { View, StyleSheet, Image, Alert, BackHandler } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthProvider, useAuth } from '../../Context';
import { doResetPassword, getToken, getDeviceIdentifier, submitNewPass } from "../functions/Helpers";
import alert from '../functions/alert';

// const axios = require('axios');
// const md5 = require('md5');

const styles = StyleSheet.create({
    container: {
        paddingTop: 35,
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
        flex: 1,
        backgroundColor: '#fff'
    },
    show: {
        display: 'flex'
    },
    hide: {
        display: 'none'
    }
});


const ResetPassword = ({ navigation, route }) => {

    const [email, setEmail] = React.useState('');
    const [successToken, setSuccessToken] = React.useState(false);
    const [successReset, setSuccessReset] = React.useState(false);

    // const [password, setPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [loadingReset, setLoadingReset] = React.useState(false);
    const [showForm, setShowForm] = React.useState(false);
    const [token, setToken] = React.useState('')
    const [nik, setNik] = React.useState('')
    const [pass, setPass] = React.useState('')
    const [passConf, setPassConf] = React.useState('')

    const [secureTextEntry, setSecureTextEntry] = React.useState(true);

    const { setLoggedIn } = useAuth();

    const toggleSecureEntry = () => {
        setSecureTextEntry(!secureTextEntry);
    };

    const renderIcon = (props) => (
        <TouchableWithoutFeedback onPress={toggleSecureEntry}>
            <Icon width={25} height={25} name={secureTextEntry ? 'eye-off' : 'eye'} fill="blue" />
        </TouchableWithoutFeedback>
    );

    useEffect(() => {
        if (successReset) {
            navigation.navigate('HalamanLogin')
        }
    }, [successReset]);

    const requestToken = () => {

        setLoading(true);

        (async () => {
            try {
                const appid = await getDeviceIdentifier();
                await doResetPassword(appid, nik, email, setSuccessToken, setLoading);

            } catch (error) {
                alert(
                    "Error!",
                    "Terjadi Kesalahan!",
                    [{ text: "Ok" },
                    {
                        text: 'Detil',
                        onPress: () => {
                            navigation.navigate('ErrorDetailScreen', { error: error })
                        }
                    }],
                    {
                        cancelable: true
                    }
                )
                console.log(error);
                setLoading(false);
            }
        })()
    }

    const resetPwd = () => {

        if (!token || !pass || !passConf) {
            alert(
                "Error!",
                "Harap isi data dengan benar",
                [{ text: "Ok" }],
                {
                    cancelable: true
                }
            )
            return
        }

        if (pass !== passConf) {
            alert(
                "Error!",
                "Password harus sama dengan password konfirmasi",
                [{ text: "Ok" }],
                {
                    cancelable: true
                }
            )
            return
        }

        if (pass.length < 8) {
            alert(
                "Error!",
                "Password minimal 8 karakter",
                [{ text: "Ok" }],
                {
                    cancelable: true
                }
            )
            return
        }

        setLoadingReset(true);

        (async () => {
            try {
                const appid = await getDeviceIdentifier();
                await submitNewPass(appid, nik, token, pass, passConf, setSuccessReset, setLoadingReset);

            } catch (error) {
                alert(
                    "Error!",
                    "Terjadi Kesalahan!",
                    [{ text: "Ok" },
                    {
                        text: 'Detil',
                        onPress: () => {
                            navigation.navigate('ErrorDetailScreen', { error: JSON.stringify(error, null, 2) })
                        }
                    }],
                    {
                        cancelable: true
                    }
                )
                console.log(error);
                setLoadingReset(false);
            }
        })()
    }


    return (
        <View style={styles.container}>
            <Text category="h6" style={{ fontWeight: 'bold', textAlign: 'center', marginTop: 20, marginBottom: 40 }}>RESET/ATUR PASSWORD</Text>

            <Input
                placeholder='Masukkan NIK pemilik perangkat'
                value={nik}
                autoCapitalize="none"
                onChangeText={nextValue => setNik(nextValue)} />
            <Input
                placeholder='Masukkan email yang didaftarkan'
                value={email}
                keyboardType="email-address"
                autoCapitalize="none"
                onChangeText={nextValue => setEmail(nextValue)} />

            <Text appearance="hint" status="danger">PERHATIAN! Email yang diinput diatas harus sama dengan email yang terdaftar di admin absen!</Text>

            <Button
                style={{ marginTop: 40 }}
                appearance='outline'
                status='primary'
                onPress={() => requestToken()}
                disabled={loading}>{loading ? "Harap tunggu..." : "Kirim Email"}</Button>

            <Divider style={{ marginVertical: 10 }} />

            <View style={successToken ? styles.show : styles.hide}>
                <Input
                    placeholder='Masukkan token yang didapat melalui email'
                    value={token}
                    style={{ marginBottom: 8 }}
                    autoCapitalize="none"
                    onChangeText={nextValue => setToken(nextValue)} />
                <Input
                    placeholder='Masukkan password baru'
                    value={pass}
                    style={{ marginBottom: 8 }}
                    onChangeText={nextValue => setPass(nextValue)}
                    secureTextEntry={secureTextEntry}
                    autoCapitalize="none"
                    accessoryRight={renderIcon}
                />
                <Input
                    placeholder='Masukkan ulang password baru'
                    value={passConf}
                    style={{ marginBottom: 8 }}
                    onChangeText={nextValue => setPassConf(nextValue)}
                    secureTextEntry={secureTextEntry}
                    autoCapitalize="none"
                    accessoryRight={renderIcon}
                />

                <Button
                    style={{ marginTop: 20 }}
                    appearance='outline'
                    status='success'
                    onPress={() => resetPwd()}
                    disabled={loadingReset}>{loadingReset ? "Harap tunggu..." : "RESET"}</Button>
            </View>
        </View>
    );

};

export default ResetPassword;