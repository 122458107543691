import React from 'react';
import { Text, Button, Icon, Divider, Avatar, Modal, Layout, Card } from '@ui-kitten/components';
import { View, StyleSheet, Alert, ScrollView } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthProvider, useAuth } from '../../Context';
import { getToken, getDeviceIdentifier, removeDataPegawai, version } from "../functions/Helpers";
import { apiUrl } from '../functions/Constants';
import * as Location from 'expo-location';
import Spinner from 'react-native-loading-spinner-overlay';
import alert from '../functions/alert';

const axios = require('axios')

const styles = StyleSheet.create({
    containerBtnAbsen: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    card: {
        flex: 1,
        margin: 4,
        alignItems: 'center',
    },
    top: {
        marginTop: 40,
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 4,
    },
    containerBtn: {
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexDirection: 'row',
        flex: 1
    },
    button: {
        margin: 2,
    },
    containerInfo: {
        marginLeft: 10,
        marginRight: 10,
        flexDirection: 'column'
    },
    row: {
        marginHorizontal: 10,
        flexDirection: 'row',
        marginVertical: 5
    },
    details: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 4,
        marginHorizontal: 10
    },
    title: {
        marginHorizontal: 8,
    },
    btnAbsen: {
        marginHorizontal: 10
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    sudahAbsen: {
        padding: 10,
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        backgroundColor: 'rgba(75,167,70,0.3)'
    },
    butuhApproval: {
        padding: 10,
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        backgroundColor: 'rgba(229,230,22,0.5)'
    },
    absenDitolak: {
        padding: 10,
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        backgroundColor: 'rgba(216,64,58,0.3)'
    },
    belumAbsen: {
        padding: 10,
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        backgroundColor: 'rgba(216,64,58,0.3)'
    },
});


const LogOutIcon = (props) => (
    <Icon name='log-out' style={{ width: 25, height: 25 }} fill='blue' />
);
const IconAbsenMasuk = (props) => (
    <Icon name='log-in-outline' style={{ width: 30, height: 30, marginLeft: 'auto', marginRight: 'auto' }} fill='#34BAD4' />
);
const IconMulaiKunjungan = (props) => (
    <Icon name='briefcase-outline' style={{ width: 30, height: 30, marginLeft: 'auto', marginRight: 'auto' }} fill='#34BAD4' />
);
const IconAbsenKeluar = (props) => (
    <Icon name='log-out' style={{ width: 30, height: 30, marginLeft: 'auto', marginRight: 'auto' }} fill='#EDA565' />
);
const IconSelesaiKunjungan = (props) => (
    <Icon name='done-all-outline' style={{ width: 30, height: 30, marginLeft: 'auto', marginRight: 'auto' }} fill='#EDA565' />
);
const IconCheck = (props) => (
    <Icon name='checkmark-square-2' style={{ width: 30, height: 30 }} fill='#4ba746' />
);
const IconNotCheck = (props) => (
    <Icon name='close-square' style={{ width: 30, height: 30 }} fill='#d8403a' />
);
const IconRekapAbsen = (props) => (
    <Icon name='calendar-outline' style={{ width: 30, height: 30, marginLeft: 'auto', marginRight: 'auto' }} fill='#0fba81' />
);

const HalamanAbsen = ({ navigation, route }) => {
    const { loggedIn, setLoggedIn, setDeviceRegistered } = useAuth()
    const [nik, setNIK] = React.useState('---');
    const [nama, setNama] = React.useState('---');
    const [jk, setJk] = React.useState('1');
    const [isAdmin, setIsAdmin] = React.useState('');
    const [tanggal, setTanggal] = React.useState('');
    const [jam, setJam] = React.useState('');
    const [loadingRekap, setLoadingRekap] = React.useState(false);
    const [jamMasuk, setJamMasuk] = React.useState('')
    const [jamPulang, setJamPulang] = React.useState('')
    const [isAbsenMasuk, setAbsenMasuk] = React.useState(false)
    const [butuhApproval, setButuhApproval] = React.useState(false)
    const [approved, setApproved] = React.useState(null)
    const [isAbsenPulang, setAbsenPulang] = React.useState(false)
    const [catatanAbsen, setCatatanAbsen] = React.useState([])
    const [spinnerShow, setSpinnerShow] = React.useState(false)

    const IconHalamanPersetujuan = (props) => (
        <Icon
            name='alert-triangle-outline'
            style={{ width: 30, height: 30, marginLeft: 'auto', marginRight: 'auto' }}
            fill='orange'
        />
    );

    const logout = () => {
        alert(
            "Logout?",
            "Anda yakin ingin Logout? ",
            [{
                text: "Ya",
                onPress: () => {
                    (async () => await removeDataPegawai())();
                    setLoggedIn(false);
                }
            },
            { text: "Batal" }],
            {
                cancelable: true
            }
        )
    }

    const getRekapAbsenToday = (nikAbsen) => {
        setLoadingRekap(true);
        (async () => {
            try {
                const appid = await getDeviceIdentifier();
                await getToken();
                var token = await AsyncStorage.getItem('@token');
                const result = await axios.post(`${apiUrl}absen/rekap_absen_today`, {
                    nik: nikAbsen
                }, {
                    headers: {
                        'x-token': token,
                        'x-appid': appid,
                        'x-version': version
                    }
                })

                const data = result.data
                console.log("hasil rekap")
                console.log(data)
                if (parseInt(data.metadata.code) == 200) {
                    if (data.response.status) {
                        setButuhApproval(data.response.rekap.needs_approval === "1")
                        setApproved(data.response.rekap.approved)

                        let statusApproved = data.response.rekap.needs_approval === "1" ?
                            (data.response.rekap.approved === "0" ? " (Ditolak)" : (data.response.rekap.approved === null ? " (Menunggu)" : "")) : ""

                        if (data.response.rekap.masuk) {
                            setAbsenMasuk(true)
                            setJamMasuk(data.response.rekap.masuk + statusApproved)
                        } else {
                            setAbsenMasuk(false)
                            setJamMasuk("")
                        }

                        if (data.response.rekap.pulang) {
                            setAbsenPulang(true)
                            setJamPulang(data.response.rekap.pulang + statusApproved)
                        } else {
                            setAbsenPulang(false)
                            setJamPulang("")
                        }
                    } else {
                        alert(
                            "Gagal",
                            data.metadata.message,
                            [{ text: "Ok" }], { cancelable: true }
                        )
                        if (parseInt(data.metadata.code) == 403) {
                            setDeviceRegistered(false)
                        }
                    }
                } else {
                    alert(
                        "Gagal",
                        data.metadata.message,
                        [{ text: "Ok" }], { cancelable: true }
                    )
                }
                setLoadingRekap(false)
            } catch (e) {
                console.log(e);
                alert(
                    'Gagal',
                    'Terjadi kesalahan, harap coba lagi',
                    [{ text: 'Ok' },
                    {
                        text: 'Detil',
                        onPress: () => {
                            navigation.navigate('ErrorDetailScreen', { error: JSON.stringify(e, null, 2) })
                        }
                    }],
                    { cancelable: true }
                )
            }

        })()
    }

    const absen = async (screenName, params) => {
        try {
            setSpinnerShow(true);
            const appid = await getDeviceIdentifier();
            await getToken();
            var token = await AsyncStorage.getItem('@token');
            const nik = await AsyncStorage.getItem('@nik');
            const result = await axios.post(`${apiUrl}absen/cek_foto`, {
                nik: nik
            }, {
                headers: {
                    'x-token': token,
                    'x-appid': appid,
                    'x-version': version
                }
            })

            setSpinnerShow(false);

            const data = result.data
            if (parseInt(data.metadata.code) == 200) {
                if (data.response.status) {

                    //cek absen kemarin yang belum absen keluar

                    setSpinnerShow(true);
                    const appid = await getDeviceIdentifier();
                    await getToken();
                    var token = await AsyncStorage.getItem('@token');
                    const nik = await AsyncStorage.getItem('@nik');
                    const resultAbsenKemarin = await axios.post(`${apiUrl}absen/cek_absen_kemarin`, {
                        nik: nik
                    }, {
                        headers: {
                            'x-token': token,
                            'x-appid': appid,
                            'x-version': version
                        }
                    })
                    const dataAbsenKemarin = resultAbsenKemarin.data
                    setSpinnerShow(false);
                    console.log(dataAbsenKemarin);
                    if (parseInt(dataAbsenKemarin.metadata.code) == 200) {
                        if (dataAbsenKemarin.response.ada_absen_belum_selesai) {
                            alert(
                                "Perhatian",
                                "Anda belum melakukan absen keluar kemarin.\nApa anda ingin melakukan absen keluar untuk kemarin terlebih dahulu?",
                                [{
                                    text: "Tidak, lanjutkan absen hari ini",
                                    onPress: () => {
                                        navigation.navigate(screenName, params)
                                    },
                                    style: 'cancel'

                                },
                                {
                                    text: "Ya, lakukan absen kemarin",
                                    onPress: () => {
                                        const tanggal = new Date()
                                        tanggal.setDate(tanggal.getDate() - 1)
                                        navigation.navigate("CameraAbsenKemarin", {
                                            tanggal: dataAbsenKemarin.response.tanggal,
                                            tanggalDisplay: dataAbsenKemarin.response.tanggal_display
                                        })
                                    },
                                    style: 'default',
                                    isPreferred: true
                                }],
                                { cancelable: true }
                            )
                        } else {
                            navigation.navigate(screenName, params)
                        }
                    } else {
                        alert(
                            "Gagal",
                            data.metadata.msg,
                            [{
                                text: "Ok",
                            }], { cancelable: true }
                        )
                    }

                } else {
                    alert(
                        "Perhatinan",
                        "Anda belum memiliki foto awal tersimpan. Anda akan diarahkanu untuk mengambil foto awal absen.",
                        [{
                            text: "Ok",
                            onPress: () => {
                                navigation.navigate("CameraFoto")
                            }
                        }], { cancelable: true }
                    )
                    if (parseInt(data.metadata.code) == 403) {
                        alert(
                            "Perhatinan",
                            data.metadata.code,
                            [{
                                text: "Ok",
                            }], { cancelable: true }
                        )
                        setDeviceRegistered(false)
                    }
                }
            } else {
                alert(
                    "Gagal",
                    data.metadata.message,
                    [{ text: "Ok" }], { cancelable: true }
                )
                if (parseInt(data.metadata.code) == 403) {
                    setDeviceRegistered(false)
                }
            }
        } catch (e) {
            console.log(e);
            alert(
                'Gagal',
                'Terjadi kesalahan, harap coba lagi',
                [{ text: 'Ok' },
                {
                    text: 'Detil',
                    onPress: () => {
                        navigation.navigate('ErrorDetailScreen', { error: JSON.stringify(e, null, 2) })
                    }
                }],
                { cancelable: true }
            )
        }
    }

    React.useEffect(() => {
        if (!loggedIn) {
            return;
        }
        (async () => {
            try {
                //check location service
                var locationEnabled = await Location.hasServicesEnabledAsync();
                if (!locationEnabled) {
                    try {
                        // console.log('enabling gps')
                        await Location.enableNetworkProviderAsync();
                    } catch (e) {
                        alert(
                            "Peringatan!",
                            "Tidak dapat melakukan absensi, harap periksa GPS atau jaringan internet perangkat!",
                            [{ text: "Ok" },
                            {
                                text: 'Detil',
                                onPress: () => {
                                    navigation.navigate('ErrorDetailScreen', { error: JSON.stringify(e, null, 2) })
                                }
                            }], { cancelable: true }
                        )
                        // return;
                    }
                }

                const nik = await AsyncStorage.getItem('@nik')
                const nama = await AsyncStorage.getItem('@nama')
                const jk = await AsyncStorage.getItem('@jk')
                const isAdmin = await AsyncStorage.getItem('@isAdmin')

                if (nik && nama) {
                    setNIK(nik);
                    setNama(nama);
                    setJk(jk);
                    setIsAdmin(isAdmin);
                } else {
                    await removeDataPegawai()
                    setLoggedIn(false);
                }


            } catch (e) {
                alert(
                    "Gagal!",
                    "Harap logout dan login kembali!",
                    [{ text: "Ok" },
                    {
                        text: 'Detil',
                        onPress: () => {
                            navigation.navigate('ErrorDetailScreen', { error: JSON.stringify(e, null, 2) })
                        }
                    }], { cancelable: true }
                )
                console.log(e);
            }

        })();

    }, []);

    React.useEffect(() => {
        const focusHandler = navigation.addListener('focus', async () => {
            const nik = await AsyncStorage.getItem('@nik')
            getRekapAbsenToday(nik)
        });
        return focusHandler;
    }, [navigation]);

    return (
        <ScrollView style={{ backgroundColor: '#fff', width: "100%" }}>
            <Spinner
                visible={spinnerShow}
                textContent={'Loading...'}
                textStyle={{ color: "#FFF" }}
            />
            <View style={styles.top}>
                <View style={styles.containerBtn}>
                    <Button
                        style={styles.button}
                        appearance='ghost'
                        status='primary'
                        accessoryRight={LogOutIcon}
                        onPress={() => logout()}>Logout</Button>
                </View>
            </View>
            <View style={{ alignContent: 'center', justifyContent: 'center', marginBottom: 10 }}>
                <Text style={{ marginHorizontal: 6, textAlign: 'center', fontWeight: 'bold' }} category='h5'>ABSEN MOBILE</Text>
            </View>


            <View style={styles.details}>
                <Avatar size='giant' source={jk == '1' ? require('../../assets/profile-male.png') : require('../../assets/profile-female.png')} />
                <View style={{ flexDirection: 'column' }}>
                    <Text style={styles.title} category='h6'>{nama}</Text>
                    <Text style={styles.title} appearance="hint">{nik}</Text>
                </View>
            </View>

            <Divider style={{ marginVertical: 20 }} />
            <View style={{ marginHorizontal: 10, flexDirection: 'column' }}>
                <Text category="h6" style={{ fontWeight: 'bold', textAlign: 'center' }}>REKAP ABSEN HARI INI</Text>
                {
                    loadingRekap ?
                        <Text>Loading...</Text>
                        :
                        <View style={{ flexDirection: 'row', marginTop: 20 }}>
                            <View style={isAbsenMasuk ?
                                (!butuhApproval ? styles.sudahAbsen :
                                    (approved === null ? styles.butuhApproval : (approved === "1" ? styles.sudahAbsen : styles.absenDitolak))
                                )
                                : styles.belumAbsen}>
                                <Text style={{ fontWeight: 'bold' }}>ABSEN MASUK</Text>
                                {
                                    isAbsenMasuk ?
                                        <View style={{ flexDirection: 'row' }}>
                                            <IconCheck />
                                            <Text style={{ textAlignVertical: 'center' }}>{jamMasuk}</Text>
                                        </View>
                                        :
                                        <IconNotCheck />
                                }
                            </View>
                            <View style={isAbsenPulang ?
                                (!butuhApproval ? styles.sudahAbsen :
                                    (approved === null ? styles.butuhApproval : (approved === "1" ? styles.sudahAbsen : styles.absenDitolak))
                                ) : styles.belumAbsen}>
                                <Text style={{ fontWeight: 'bold' }}>ABSEN KELUAR</Text>
                                {
                                    isAbsenPulang ?
                                        <View style={{ flexDirection: 'row' }}>
                                            <IconCheck />
                                            <Text style={{ textAlignVertical: 'center' }}>{jamPulang}</Text>
                                        </View>
                                        :
                                        <IconNotCheck />
                                }
                            </View>
                        </View>
                }
            </View>
            <Divider style={{ marginVertical: 20 }} />
            <Layout style={styles.containerBtnAbsen} level='1'>
                <Card style={styles.card}
                    onPress={() => {
                        absen('CameraAbsen', { jenis: 1 })
                    }}
                >
                    <IconAbsenMasuk />
                    <Text style={{ textAlign: 'center' }}>Absen Masuk</Text>
                </Card>

                <Card style={styles.card}
                    onPress={() => {
                        absen('CameraAbsen', { jenis: 2 })
                    }}
                >
                    <IconAbsenKeluar />
                    <Text style={{ textAlign: 'center' }}>Absen Keluar</Text>
                </Card>
            </Layout>
            <Layout style={styles.containerBtnAbsen} level='1'>
                <Card style={styles.card}
                    onPress={() => {
                        absen('CameraKunjungan', { jenis: 1 })
                    }}
                >
                    <IconMulaiKunjungan />
                    <Text style={{ textAlign: 'center' }}>Mulai Kunjungan</Text>
                </Card>

                <Card style={styles.card}
                    onPress={() => {
                        absen('CameraKunjungan', { jenis: 2 })
                    }}
                >
                    <IconSelesaiKunjungan />
                    <Text style={{ textAlign: 'center' }}>Selesai Kunjungan</Text>
                </Card>
            </Layout>
            <Layout style={styles.containerBtnAbsen} level='1'>
                <Card style={{ ...styles.card, alignItems: 'center' }}
                    onPress={() => {
                        navigation.navigate('RekapAbsen')
                    }}
                >
                    <IconRekapAbsen />
                    <Text style={{ textAlign: 'center' }}>Rekap Absen</Text>
                </Card>

                {
                    isAdmin == '1' ?
                        <Card style={{ ...styles.card, alignItems: 'center' }}
                            onPress={() => {
                                navigation.navigate('PersetujuanAbsen')
                            }}
                        >
                            <IconHalamanPersetujuan />
                            <Text style={{ textAlign: 'center' }}>Persetujuan Absen</Text>
                        </Card>
                        : <></>
                }


            </Layout>
        </ScrollView>
    )
};

export default HalamanAbsen;